'use client';

import {
  CreateResponseRequest,
  CreateResponseResponse,
  FORM_STATUS,
  GetPublishedFormByIdResponse,
} from '@formo/shared';
import { FormResponse, FormSchema, StepSchema } from '@formo/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';
import { StepType } from '~/constants/fields';
import client from '~/lib/client';

import { Formo, Spinner } from '../_components/common';
import { FormRenderer } from '../_components/form/FormRenderer';
import FormBuilderContextProvider from '../context/BuildFormContext';
import TokenGateProvider from '../context/TokenGateContext';
import useFormBuilder from '../hooks/useFormBuilder';
import useTokenGate from '../hooks/useTokenGate';

type FormResponsePageProps = {
  onSubmit: (value: any, steps: string[]) => Promise<FormResponse | Error>;
  form: FormSchema;
};

const FormResponsePage: React.FC<FormResponsePageProps> = ({
  onSubmit,
  form: formInfo,
}) => {
  const { tokenGatePage } = useTokenGate();
  const { steps, formStatus } = useFormBuilder();
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const isClosed = formStatus === FORM_STATUS.CLOSED;

  const closedPage: StepSchema = {
    id: 'closed',
    name: 'Closed page',
    fields: [
      {
        id: 'closed_field',
        label: 'Form closed',
        subText: 'This form is no longer accepting responses.',
        fieldType: 'closed',
        fieldSpecs: {
          hideIcon: false,
          hideFormo: false,
        },
      },
    ],
    defaultNextStep: '',
    type: StepType.CLOSED,
  };

  const copySteps: StepSchema[] = useMemo(() => {
    const array = [...steps].sort((s) =>
      s.type === StepType.WELCOME ? -1 : 0,
    );
    // Add token gate page to the steps
    if (tokenGatePage && tokenGatePage.fields.length !== 0) {
      if (array[0]?.type === StepType.WELCOME) {
        // If the first step is a welcome page, add the token gate page after it
        array.splice(1, 0, tokenGatePage);
      } else {
        // If the first step is not a welcome page, add the token gate page at the beginning
        array.unshift(tokenGatePage);
      }
    }
    if (isClosed) {
      array.push(closedPage);
    }
    return array.map((step, index) => ({
      ...step,
      defaultNextStep: array[index + 1]?.id || '',
    }));
  }, [formInfo, formStatus, tokenGatePage, steps]);

  const customFormInfo: FormSchema = useMemo(() => {
    return {
      ...formInfo,
      status: formStatus,
      steps: copySteps,
    } as FormSchema;
  }, [formInfo, formStatus, tokenGatePage, copySteps]);
  return (
    <>
      <div className="fixed inset-0">
        <FormRenderer
          {...customFormInfo}
          sameAsPublished={true}
          onSubmit={onSubmit}
          activeStepIndex={activeStepIndex}
          setActiveStepIndex={setActiveStepIndex}
        />
      </div>
      <div
        className="fixed inset-x-0 bottom-0 flex h-[66px] items-center justify-end gap-1.5 bg-white/50 px-[42px] max-[720px]:justify-center"
        style={{
          WebkitBackdropFilter: 'blur(10px)',
          backdropFilter: 'blur(10px)',
        }}
      >
        <span className="text-sm font-medium text-black-500">
          Create your own form with
        </span>
        <Link href="https://formo.so/">
          <Formo size={13} />
        </Link>
      </div>
    </>
  );
};

function Wrapper({}) {
  const { formId: slug } = useParams() as { formId: string };
  const teamSlug = useMemo(() => {
    const hostname = window.location.hostname;
    if (hostname.startsWith('localhost')) return null;
    const subdomain = hostname.split('.')[0];
    if (subdomain === 'app') return null;
    return subdomain;
  }, []);

  const {
    data: form,
    isPending,
    error,
  } = useQuery<GetPublishedFormByIdResponse>({
    queryKey: ['forms', 'published', slug],
    queryFn: async () =>
      (
        await client.get(`/api/forms/${slug}/published`, {
          params: {
            teamSlug,
          },
        })
      ).data,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!slug,
    staleTime: 5 * 1000, // Prevent duplicated requests when redirecting
  });

  const { mutateAsync } = useMutation<
    CreateResponseResponse,
    Error,
    CreateResponseRequest['body']
  >({
    mutationFn: async (data) =>
      (await client.post(`/api/forms/${form?.id}/responses`, data)).data,
  });

  useEffect(() => {
    if (!slug || !form) return;
    document.title = `${form.title}`;
    const titleSlug = form.title.toLowerCase().replace(/\s/g, '-');
    const formSlug = `${encodeURIComponent(titleSlug)}-${form.id}`;
    // Navigate to default or custom slug
    if (slug !== formSlug && slug !== form.slug) {
      // The custom slug is only valid when it matches the `form.slug with` appropriate settings and team slug
      const url =
        form.slug && form.settings.linkOption === 'subdomain' && teamSlug
          ? `/${form.slug}`
          : `/${formSlug}`;
      // Soft redirect to the default slug
      window.history.replaceState({}, '', url);
    }
  }, [form, slug]);

  if (isPending) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!form || error || !slug) {
    return (
      <div className="fixed inset-0 flex flex-col items-center justify-center bg-white">
        <h2 className="text-3xl font-bold">404</h2>
        <p className="mt-3 px-5 text-center">
          We couldn't find the form you're looking for
          <br />
          Please check the URL or contact the form owner
        </p>
      </div>
    );
  }

  const onSubmit = async (
    value: any,
    steps: string[],
  ): Promise<FormResponse | Error> => {
    try {
      const res = await mutateAsync({
        data: value,
        stepIds: steps,
      });
      return res;
    } catch (error) {
      console.error(error);
      return error as Error;
    }
  };

  return (
    <FormBuilderContextProvider isResponder initialForm={form as FormSchema}>
      <TokenGateProvider>
        <FormResponsePage onSubmit={onSubmit} form={form as FormSchema} />
      </TokenGateProvider>
    </FormBuilderContextProvider>
  );
}

export default Wrapper;
